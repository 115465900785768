'use strict';

/* refactored and edited code from Page Designer einstein-recommendations.js */

const appendToUrl = require('../util/appendToUrl');

const EinsteinRecommendations = {};

EinsteinRecommendations.init = () => {
    EinsteinRecommendations.loadRecommendations();
};

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {*} parentElement - Element
 * @returns {Object} - Category- || productdata || null;
 */
EinsteinRecommendations.createAnchor = (parentElement) => {
    const einsteinParameters = parentElement.dataset;
    if (einsteinParameters.categoryId) {
        return [{
            id: einsteinParameters.categoryId
        }];
    }

    if (einsteinParameters.primaryProductId) {
        return [{
            id: einsteinParameters.primaryProductId,
            sku: einsteinParameters.secondaryProductId,
            type: einsteinParameters.alternativeGroupType,
            alt_id: einsteinParameters.alternativeGroupId
        }];
    }

    return null;
};

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {dw.util.EinsteinUtils} -
 */
EinsteinRecommendations.getEinsteinUtils = () => {
    const einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
};

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
EinsteinRecommendations.loadRecommendations = () => {
    const einsteinUtils = EinsteinRecommendations.getEinsteinUtils();
    if (einsteinUtils) {
        const recommendationTiles = Array.from(document.querySelectorAll('.js-page-designer-einstein-recommendations'));
        recommendationTiles.forEach(parentElement => EinsteinRecommendations.processRecommendationsTile(parentElement, einsteinUtils, EinsteinRecommendations.createAnchor(parentElement)));
    }
};

/**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {*} parentElement -
 * @param {dw.util.EinsteinUtils} einsteinUtils -
 * @param {Array} anchorsArray -
 */
EinsteinRecommendations.processRecommendationsTile = (parentElement, einsteinUtils, anchorsArray) => {
    const einsteinParameters = parentElement.dataset;
    const recommender = einsteinParameters.recommender;

    const params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    const recommendationsReceived = (einsteinResponse) => {
        EinsteinRecommendations.fillEinsteinDomElement(einsteinResponse, parentElement);
    };

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || [];
        einsteinUtils.widgets.push({
            recommenderName: einsteinParameters.recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
};

/**
 * Renders the einstein response into a given dom element
 * @param {Object} einsteinResponse -
 * @param {*} parentElement -
 */
EinsteinRecommendations.fillEinsteinDomElement = (einsteinResponse, parentElement) => {
    const recommendedProducts = einsteinResponse[parentElement.dataset.recommender].recs;
    if (recommendedProducts && recommendedProducts.length > 0) {
        const productIds = recommendedProducts.map(recommendedProduct => {
            return recommendedProduct.id;
        });
        const url = appendToUrl(parentElement.dataset.productLoadUrl, {
            ids: JSON.stringify(productIds)
        });
        $.ajax({
            url: url,
            type: 'GET',
            success: (response) => {
                parentElement.innerHTML = response;
                $(parentElement).addClass('product-slider');
            }
        });
    }
};

module.exports = EinsteinRecommendations;
